.top-bar {
    width: 100%;
    display: flex;
    justify-content: stretch;
    padding: 0px;
    
    background-repeat: repeat-x;
    background-color: black;
    color: white;

}

/*background: url('../../images/nav_bg.webp');*/
 
.top-bar h1 {
    margin-left: 80px;
    margin-right: 80px;
    width: 100%;
    text-align: center;
    font-size: xx-large;

}
.slogan {
    position: absolute;
    bottom: 0px;
    right: 0px;
    white-space: nowrap;

    font-weight: bold;
    font-style: italic;
}