/* Generic*/
body {
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    padding: 0;
    /*background: rgb(245,245,245);*/
    background: linear-gradient(90deg, rgba(212,212,212,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(212,212,212,1) 100%);
    text-decoration: none;
    color: var(--ba-red);
    font-weight: bold;
}

.problem-text {
    font-size: larger;
    font-style: italic;
    font-style: italic;
}

.problem-solution {
    text-align: center;
    font-size: larger;
}
strong {
    font-weight: bold;
}

:root {
    --ba-color-tint: #7f0000;
    --ba-color-alertbar-background: #823131;
    --ba-color-alertbar-button-background: #fff;
    --ba-color-note:#aeb9c5;
    --toolbar-height: 80px;
    --coockie-consent-heigth: 100px;
}

a, a:hover, a:visited, a:active {
    color: var(--ba-color-note);
}
/* spinner*/
.loader {
    border: 16px solid var(--ba-color-note); 
    border-top: 16px solid var(--ba-color-tint); 
    border-radius: 50%;
    width: 30px;
    height:30px;
    animation: spin 2s linear infinite;
    margin:auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* spinner end*/
img {
    max-width: 100%;
}


/* Containers*/

.header {
    position: fixed;
    z-index: 999;
    width: 100%;
}
navbar {
    position: fixed;
    width: 100%;
    top:0px;
    z-index: 100;
    height: var(--toolbar-height);
}

#page-wrap {
    margin: 0 auto;
    padding: 15px 0 0 0;
    background: #fff;
    max-width: 960px;
    position: relative;
    top: 70px;
  
}

.row-large {
    display: flex;
    justify-content: space-around
}

.row-small {
}

.div-right {
    display: flex;
    justify-content: flex-end;
}

.float-right {
    float: right;
    padding: 20px;
    padding-top: 0px;
}

.appIntro {
    display: flex;
    justify-content: center;
}

.appIntro-icon {
    width: 150px;
}

.appIntro-text-box {
    vertical-align: top;
    padding: 0 20px;
}

/* outdoor navigator*/
.screenShootImg {
    width: 33%;
    padding: 2%;
    text-align: center;
}
.container-footer {
    padding: 5px 0;
    margin-top: 25px;
    background: #3c3c3c;
    font-size: 14px;
    line-height: 5px;
    text-align: center;
    color: var(--ba-color-note);
    position: relative;
}