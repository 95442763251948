/* Position and sizing of burger button */
.bm-menu {
    
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    background:#222;
}


.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 25px;
}

.email {
    margin-top: 20px;
    font-size: smaller;
}

    .email a, .container-footer a:visited {
        color: var(--ba-color-note)
    }

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: var(--ba-color-tint);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: var( --ba-color-tint);
    padding: 0.8em;
    padding-top: 0px;
    padding-bottom: 0px;
}

/* Individual item */
.bm-item, .bm-item:active, .bm-item:link, .bm-item:hover, .bm-item:visited {
    color: var( --ba-color-tint);
    display: inline-block;
    padding: 5px;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
/* Individual item */

.bm-title {
   color:gray;
}

.bm-title:hover {
    color: gray;
}

.bm-menu .social-icon {
    width: 40px !important;
    height: 40px !important;
    margin:5px;
}