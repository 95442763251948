.follow a{
    margin:10px;
}
.social-icon-fix {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align:middle;
}
.social-icon-fix img{
 
}

.hover-button--off div  {
   display:inline;
   height:50px;
}
.hover-button--off * {
    vertical-align:middle;
    padding:5px;
       
}
.share {

    padding:10px;
    text-align:center;
    vertical-align:middle;
}
    .share:hover {
        width: 200px;
    }
.share-container div, .share-container span {
    display: inline;
    vertical-align:top;
    line-height:50px;
    padding:5px;
    color:black;
    font-size:small;
}

.hover-button--on {
    padding: 10px;
    background-color: white;
    position: absolute;
    z-index: 9;
    border-radius: 10px;
    -webkit-box-shadow: 11px 11px 19px -20px rgba(0,0,0,1);
    -moz-box-shadow: 11px 11px 19px -20px rgba(0,0,0,1);
    box-shadow: 11px 11px 19px -20px rgba(0,0,0,1);
    vertical-align: middle;

}


.hover-button--on {
    -webkit-transition: width 2s; /* Safari */
    transition: width 2s;
    display: none;
}

.hover-button:hover .hover-button--on {
     -webkit-transition: width 2s; /* Safari */
    transition: width 2s;
    display: block;
  
}