
#cookieConsent {
    position: relative !important;
    padding: 10px;
    background: var(--ba-color-alertbar-background);
    color: white;
    left: 0px;
    width: 100%;
    text-align: center;
    top: 0px;
    overflow: auto;
}

#cookieConsent-buttons {
    display: block;
    padding: 0px;
    margin: 0px;
    float: right;
    list-style: none;
}

#cookieConsent button {
    background: var(--ba-color-alertbar-button-background);
    border: 0px;
    border-radius: 5px;
    box-shadow: none;
    color: var(--ba-color-tint);
    cursor: pointer;
    font-size: 13px;
    padding: 5px;
    height: 2em;
    margin: 10px;
    margin-right: 20px;
    float: right
}

.options {
    list-style: none;
}
/* Customize the label (the container) */
.chk-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default checkbox */
    .chk-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-style: solid;
    border-color: var(--ba-color-tint);
}


/* On mouse-over, add a grey background color */
.chk-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chk-container input:checked ~ .checkmark {
    background-color: var(--ba-color-tint);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.chk-container input:disabled ~ .checkmark{
    background-color:darkgray;
}
/* Show the checkmark when checked */
.chk-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.chk-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
